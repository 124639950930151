import { encryptDataWithKey } from "../utils/common"

class AeraAvailaColor {
  id: string = ''
  colorName: string = ''
  colorPath: string = ''
  alt: string = ''
  bikeVideo: string = ''
  bikeImage: string = ''
  thumbnailBikeImg: string = ''
  bikeVideoMP4: string = ''
  backgroundColor?: string = ''
  colorDescription: string = ''
  bikeDirectoryName: string = ''
}
class AeraDetail {
  range: number = 0
  power: string = ''
  batteryCapacity = ''
  description: string = ''
  productName: string = ''
  productPrice: string = ''
  acceleration: string = ''
  emiAmount: string = '0'
  prebookAmount: string = '0'
  emiStartPrice: string = ''
  bikeTotalPrice: string = ''
  bikeModalImage: string = ''
  discountApplied: string = ''
  productId: string = AERA_TYPE.FIVE_THOUSHAND
  availableColors: Array<AeraAvailaColor> = []
}

enum PREBOOKING_STEP {
  LOGIN = 1,
  SUMMARY = 6,
  PAYMENT = 6,
  SIGN_UP = 3,
  CONFIGURE = 5,
  OTP_VERIFICATION = 2,
  ACTIVITE_ACCOUNT = 4,
}

enum AERA_TYPE {
  FIVE_THOUSHAND = '1',
  FIVE_THOUSHAND_PLUS = '2',
}

enum BILLDESK_PAYMENT_STATUS {
  FAILED = '0399',
  SUCCESS = '0300',
  PENDING = '0002',
}

class CreatePrebooking extends AeraDetail {
  selectedCity: string = ''
  selectedState: string = ''
  selectedColorId: string = ''
  selectedBikeModelName: string = ''
}

class OrderLink {
  href: string = ''
  rel: string = ''
  method: string = ''
  parameters?: any = null
  valid_date?: any = null
  headers?: { authorization: string } | null = null
}
class CreateOrderDetails {
  ru: string = ''
  status: string = ''
  amount: string = ''
  mercid: string = ''
  orderid: string = ''
  currency: string = ''
  objectid: string = ''
  itemcode: string = ''
  bdorderid: string = ''
  createdon: string = ''
  next_step: string = ''
  order_date: string = ''
  additional_info: any = {}
  transactionId?: string = ''
  links: Array<OrderLink> = []
}

class TransactionResponse {
  ru: string = ''
  bankid: string = ''
  mercid: string = ''
  orderid: string = ''
  amount: string = '0.00'
  discount: string = '0.00'
  surcharge: string = '0.00'
  mode: string = ''
  transactionid: string = ''
  transaction_date: string = ''
  txn_process_type: string = 'nb'
  payment_method_type: string = ''
  transaction_error_type: string = ''
  additional_info: any = {
    additional_info7: 'NA',
    additional_info6: 'NA',
    additional_info9: 'NA',
    additional_info8: 'NA',
    additional_info1: 'NA',
    additional_info3: 'NA',
    additional_info2: 'NA',
    additional_info5: 'NA',
    additional_info4: 'NA',
    additional_info10: 'NA',
  }
  itemcode: string = ''
  currency: string = ''
  objectid: string = ''
  auth_status: string = ''
  charge_amount: string = '0.00'
  transaction_error_code: string = ''
  transaction_error_desc: string = ''
}

class ExecutiveAgent {
  name: string = ''
  phone: string = ''
  email: string = ''
  executiveId: string = ''
}
class AeraDetails {
  isLoading: boolean = false
  validatePrebooking: any = {}
  availableAeras: Array<AeraDetail> = []
  availablePromoCodes: Array<PromoCode> = []
  prebookStep: number = PREBOOKING_STEP.CONFIGURE
  createPrebookingAera: CreatePrebooking = new CreatePrebooking()
  createOrderDetails: CreateOrderDetails = new CreateOrderDetails()
  transactionResponse: TransactionResponse = new TransactionResponse()
  executiveAgentList: Array<ExecutiveAgent> = []
}

class OnboardingDetails {
  tab: number = 1
  phone: string = ''
  resend: boolean = true
  resendTime: number = 0
  loaderStatus: boolean = false
  numberOtpVerified: boolean = false
  otpAttemps: number = 3
  backFromActivate: boolean = false
  checkFiveMinutes: boolean = false
}

class UserDetails {
  dob?: null
  address?: null
  whatsAppNo?: null
  imageBase64?: null
  email: string = ''
  phone: string = ''
  userToken: string = ''
  lastName: string = ''
  userName: string = ''
  firstName: string = ''
  affiliationStore?: null
  affiliationDate: string = ''
  emailVerified: boolean = false
  whatsAppVerified: boolean = false
  receiveNotifications: boolean = false
  orderList: Array<OrderDetails> = []
  profilePercentage: number = 0
  loaderStatus: boolean = false
}

class CurrentUserDetails {
  currentUserDetails: any = '';
  orderList: Array<OrderDetails> = []
}
enum LOGIN_TYPE {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  NEW_USER = 'NEW_USER',
  OLD_USER = 'OLD_USER',
}

class Subcription {
  charger: string = ''
  matterCare: string = ''
  connectivity: string = ''
}
class PriceBreakUp {
  taxes: string = ''
  bikePrice: string = ''
  itemTotal: string = ''
  total: string = ''
  subscription: Subcription = new Subcription()
  bikeOriginalPrice: string = ''
  discountApplied: string = ''
  bikeTotalPrice: string = ''
}
class EMICalculator {
  monthlyEmi: number = 0
  downPayment: number = 0
}
class LocationDetail {
  city: string = ''
  state: string = ''
  bikeColor: string = ''
  bikeModelName: string = ''
  priceBreakUp: PriceBreakUp = new PriceBreakUp()
}
class OrderDetails extends LocationDetail {
  city: string = ''
  state: string = ''
  orderId: string = ''
  bikeColor: string = ''
  orderStatus: string = ''
  createdDate: string = ''
  updatedDate: string = ''
  transactionId: string = ''
  bikeModelName: string = ''
  modifiable: boolean = true
  promoCodeAmount: string = ''
  timeStamp: string | null = null
  preBookAmount: string | null = null
  amountAfterPromoCode: string | null = null
  priceBreakUp: PriceBreakUp = new PriceBreakUp()
  payResponse: TransactionResponse = new TransactionResponse()
}

class CancelReasonContent {
  content: string = ''
}
class CancelReason {
  category: CancelReasonContent = new CancelReasonContent()
  subcategories: Array<CancelReasonContent> = []
}

class FAQCategory {
  id: number = 0
  categoryTitle: string = ''
}
class FaqMasterCategory extends FAQCategory {
  child_category_faqs: Array<FAQCategory> = []
}

class QuestionAnswerDetail {
  id: number = -1
  count: number = 0
  answer: string = ''
  question: string = ''
  isTrending: boolean = false
  subChildCategoryId?: number = -1
}
class FAQDetails {
  id: number = -1
  categoryTitle: string = ''
  faq_matersCount?: number = 0
  faq_maters: Array<QuestionAnswerDetail> = []
  master_category_faq: FAQCategory = new FAQCategory()
}
class InnovationHubFeatures {
  id: number = 0
  title: string = ''
  description: string = ''
}

class TechStackHub {
  id: number = 0
  title: string = ''
  imageUrl: string = ''
  description: string = ''
}
class InnovationHubDetails {
  videoUrl: string = ''
  publishedAt: string = ''
  totalTradeMark: number = 0
  techStackTitle: string = ''
  totalPatentFiled: number = 0
  intellactualTitle: string = ''
  techStackDescription: string = ''
  totalDesignRegistered: number = 0
  intellactualDescription: string = ''
  techStackList: Array<TechStackHub> = []
}

class WhoWeAreRewardDetail {
  id: number = 0
  rewardTitle: string = ''
  rewardMedia: string = ''
  rewardDescription: string = ''
}
class WhoWeAreRewards {
  title: string = ''
  rewardList: Array<WhoWeAreRewardDetail> = []
}

class OurStoryEvent {
  id: number = 0
  eventTitle: string = ''
  eventYear: string = ''
  eventDescription: string = ''
  eventMedia: string = ''
}

class WhoWeAreEvents {
  title: string = ''
  description: string = ''
  eventsList: Array<OurStoryEvent> = []
}

class WhoWeAreLeader {
  id: number = 1
  name: string = ''
  designation: string = ''
  profileImage: string = ''
  linkedInProfile: string = ''
  profileImageMobile: string = ''
}
class WhoWeAreLeaders {
  title: string = ''
  description: string = ''
  leadersList: Array<WhoWeAreLeader> = []
}

class WhoWeAre {
  title: string = ''
  impactGoalTitle2: string = ''
  impactGoalTitle1: string = ''
  missionVisionAppImage: string = ''
  missionVisionWebImage: string = ''
  impactGoalDescription1: string = ''
  impactGoalDescription2: string = ''
  rewards: WhoWeAreRewards = new WhoWeAreRewards()
  leaders: WhoWeAreLeaders = new WhoWeAreLeaders()
  ourStoryEvents: WhoWeAreEvents = new WhoWeAreEvents()
}

class BlogsCategory {
  id: number = 0
  categoryName: string = ''
  allblogs: Array<Blogs> = []
}

class Blogs {
  id: number = 0
  title: string = ''
  publishDate: string = ''
  readTime: number = 0
  matterBlogImageWeb: string = ''
  matterBlogImageApp: string = ''
  matterBlogThumbNailImageWeb: string = ''
  matterBlogThumbNailImageApp: string = ''
  blogImageDescription: string = ''
  blogIntroduction: string = ''
  blogStream1: string = ''
  blogExtraPictures: [] = []
  extraPicturesDescription: string = ''
  blogStream2: string = ''
}

class JoinWaitlistTC {
  title: string = ''
  introduction: string = ''
  listContent: Array<{ title: string, description: '' }> = []
}
class LegalPolicy {
  title: string = ''
  introduction: string = ''
  userAgreement_title: string = ''
  disclaimer_title: string = ''
  notices_title: string = ''
  eligibility_title: string = ''
  acc_reg_obligations_title: string = ''
  use_of_the_platforms_title: string = ''
  usage_restrictions: string = ''
  userAgreement: string = ''
  amendments_title: string = ''
  amendments: string = ''
  disclaimer: string = ''
  notices: string = ''
  eligibility: string = ''
  limitation_liability_title: string = ''
  limitation_liability: string = ''
  acc_reg_obligations: string = ''
  use_of_the_platforms: string = ''
  usage_restrictions_title: string = ''
  proprietary_rights_title: string = ''
  proprietary_rights: string = ''
  claimsOfInfringement_title: string = ''
  claimsOfInfringement: string = ''
  LinksToThirdPartyWebsites_title: string = ''
  LinksToThirdPartyWebsites: string = ''
  Privacy_title: string = ''
  Privacy: string = ''
  onlinePreBookingTNC_title: string = ''
  onlinePreBookingTNC: string = ''
  termination_title: string = ''
  termination: string = ''
  non_waiver_title: string = ''
  non_waiver: string = ''
  general_title: string = ''
  general: string = ''
  indemnity_title: string = ''
  indemnity: string = ''
  grievane_officer_title: string = ''
  grievane_officer: string = ''
  governingLawTitle: string = ''
  governingLaw: string = ''
  abitrations_title: string = ''
  arbitrations: string = ''
}

class PrivacyPolicy {
  title: string = ''
  introduction: string = ''
  collectionOfUsersData: string = ''
  collectionOfUsersDataContent: string = ''
  cookies: string = ''
  cookiesContent: string = ''
  usageOfTheUsersData: string = ''
  usageOfTheUsersDataContent: string = ''
  sharingOfTheUsersData: string = ''
  sharingOfTheUsersDataContent: string = ''
  security: string = ''
  securityContent: string = ''
  ThirdPartyApps: string = ''
  ThirdPartyAppsContent: string = ''
  storageOfuserData: string = ''
  storageOfuserDataContent: string = ''
  usersRight: string = ''
  usersRightContent: string = ''
  changesToThePrivacyPolicy: string = ''
  changesToThePrivacyPolicyContent: string = ''
  grievanceOfficer: string = ''
  grievanceOfficerContent: string = ''
}

class OnlinePreBookTC {
  title: string = ''
  introduction: string = ''
  listContent : Array<{title : string, description : ''}> = []
}

class HeroSection1{
  M1Black: string=''
  M1Blue: string=''
  M1Content: string=''
 M2Presenting: string=''
 M2Matter: string=''
 M2AERA: string=''
 M3MatterAERA: string=''
 M3Content: string=''
 M3CTA: string=''
 M4Black: string=''
 M4Blue: string=''
 M4Acceleration: string=''
                M4AccelerateValue:string=''
                M4AccelarationSec:string=''
                M4MotorPower:string=''
                M4MotorValue:string=''
                M4MotorKW:string=''
                M4RideModes:string=''
                RideModesValue:string=''
                RideModesText:string=''
                M4HyperShift: string=''
                HyperShiftText: string=''
                HyperShiftValue:string=''
                M4Range:string=''
                RangeValue:string=''
                RangeKM:string=''
                LaunchVideo:string=''

}

class MotorSection1{
  M6Capability: string=''
  M6Powertrain: string=''
  M6Heading: string=''
  M6cta:string=''
  M6GearBox:string=''
  GearboxContent:string=''
  LearnMoreHeading1:string=''
  LearnMoreContent1:string=''
  LearnMoreHeading2:string=''
  LearnMoreContent2:string=''
  LearnMoreHeading3:string=''
  LearnMoreContent3:string=''
  Heading1Acceleration:string=''
  Acceleration_value:string=''
  Acceleration_sec:string=''
  RideModesText:string=''
  RideModesValue:string=''
}

class PowerSection1{
  M7Capability:string=''
  M7Powerpack:string=''
  M7Heading:string=''
  M7cta:string=''
  PowerpackTitle:string=''
  PowerpackContent:string=''
  LearnMoreHeading1:string=''
  LearnMoreContent1:string=''
  LearnMoreHeading2:string=''
  LearnMoreContent2:string=''
  LearnMoreHeading3:string=''
  LearnMoreContent3:string=''
  BatteryCapacity:string=''
  BatteryCapacityValue:string=''
  BatteryCapacityKWH:string=''
  WaterDustProofing:string=''
  WaterDustValue:string=''
  WaterDustRating:string=''
  Range:string=''
  RangeValue:string=''
  RangeText:string=''
}

class ThermalSection1{
  M8Capability:string=''
  M8THermal:string=''
  M8Heading:string=''
  M8cta:string=''
  LiquidCooling:string=''
  LiquidCooling_Content:string=''
  LearnMoreHeading1:string=''
  LearnMoreContent1:string=''
  LearnMoreHeading2:string=''
  LearnMoreContent2:string=''
}

class HotspotSection1{
  M9Capability:string=''
  M9Content:string=''
}
class ColorSection1{
  M10ColourPalette:string=''
  ColourPaletteContent:string=''
}
class VuiSection1{
  M11Connectivity:string=''
  M11Content:string=''
  Corousel1Heading:string=''
  Corousel1Content:string=''
  Corousel2Heading:string=''
  Corousel2Content:string=''
  Corousel3Heading:string=''
  Corousel3Content:string=''
  Corousel4Heading:string=''
  Corouse4Content:string=''
}
class AppSection1{
  M12Connectivity:string=''
  M12Content:string=''
  Corousel1Heading:string=''
  Corousel1Content:string=''
  Corousel2Heading:string=''
  Corousel2Content:string=''
  Corousel3Heading:string=''
  Corousel3Content:string=''
  Corousel4Heading:string=''
  Corousel4Content:string=''
}
class FeatureSection1{
  Features:string=''
  FeaturesHeading:string=''
  Alloys: string=''
  Charger:string=''
  Dpad:string=''
  Graphics:string=''
  Passive:string=''
  KeyImg:string=''
  Triangle:string=''
  Storage:string=''
  SmartParking:string=''
  grrbrail:string=''
  star:string=''
  ChargerBlue:string=''
  ChargerWhite:string=''
  DpadBlue:string=''
  DpadWhite:string=''
  StarBlue:string=''
  StarWhite:string=''
  SmartParkingBlue:string=''
  SmartPrakingWhite:string=''
  KeyImgBlue:string=''
  KeyImgWhite:string=''
  AlloysBlue:string=''
  AlloysWhite:string=''
  PassiveBlue:string=''
  PassiveWhite:string=''
  TriangleBlue:string=''
  TriangleWhite:string=''
  GrrbrailBlue:string=''
  GrrbrailWhite:string=''
  StorageBlue:string=''
  StorageWhite:string=''
  GraphicsBlue:string=''
  GraphicsWhite:string=''
  ChargerContent:string=''
  GraphicsContent:string=''
  GrrbrailContent:string=''
  KeyImgContent:string=''
  PassiveContent:string=''
  SmartParkingContent:string=''
  DPadContent:string=''
  StorageContent:string=''
  TriangleContent:string=''
  ChargerMobile:string=''
  DpadMobile:string=''
  AlloysMobile:string=''
  GrabrailMobile:string=''
  GraphicsMobile:string=''
  KeyImgMobile:string=''
  PassiveMobile:string=''
  SmartParkingMobile:string=''
  StarMobile:string=''
  StorageMobile:string=''
  TriangleMobile:string=''
}
class MatterCareSection1{
  MatterCare:string=''
  MatterCareHeadingBlack:string=''
    MatterCareHeadingBlue:string=''
  Security:string=''
  Safety:string=''
  Reliability:string=''
  Performance:string=''
  FourPillarsContent:string=''
  RaceHeading:string=''
  RaceContent:string=''
}
class PriceSection1{
  Heading:string=''
  AERA500Price:string=''
  AERA5000PlusPrice:string=''
  AERA5000EMI:string=''
  AERA5000PlusEMI:string=''
}


// class HomeDetails {
//   exploreMatterTitle: string= ''
//   channelPartnerTitle: string = ''
//   channelPartnerDescription: string = ''
// }

class NewsCategory {
  id: number = 0
  categoryName: string = ''
  allnews: Array<News> = []
}

class News {
  id: number = 0
  newsTitle: string = ''
  newsDate: string = ''

  newsImageWeb: string = ''
  newsThumbNailImageWeb: string = ''

  newsPublisher: string = ''
  newsURL: string = ''

  readTime: number = 0
}

class Awards {
  id: number = 0
  awardTitle: string = ''
  awardYear: string = ''

  awardImageWeb: string = ''
  awardThumbNailImageWeb: string = ''

  awardPublisher: string = ''
  awardURL: string = ''
}

class PressReleases {
  id: number = 0
  pressTitle: string = ''
  pressDate: string = ''

  pressImageWeb: string = ''
  pressThumbNailImageWeb: string = ''

  pressPublisher: string = ''
  pressURL: string = ''

  pressFile: string = ''
}

class Leadership {
  id: number = 0
  founderName: string = ''
  founderDesignation: string = ''

  founderImageWeb: string = ''
  founderThumbNailImageWeb: string = ''

  founderBio: string = ''
}

class LogosAndTrademark {
  id: number = 0

  logoAndTrademark: string = ''
}

class Aera5000 {
  id: number = 0

  aeraBikeImg: string = ''
}

class Aera5000Plus {
  id: number = 0

  aeraBikeImg: string = ''
}

class ProductPortfolio {
  id: number = 0

  productName: string = ''
  downloadAll: string = ''
  specsheet: string = ''
}

class Home {
  availableLocationList: Array<LocationDetail> = []
  cancelReasons: Array<CancelReason> = []
  topHomeBanner: { desktopmessage: string; mobilemessage: string } = {
    desktopmessage: '',
    mobilemessage: '',
  }

  HomeDetails: { sec1heading: string; sec1learnmore: string; sec1CTA: string; sec2Heading: string;  sec2Img:string; sec2Img2:string; sec2CTA: string; sec3Redefine:string;
                 sec3Reimagine: string; partnerHeading:string; partnerOTO: string ; partnerWithUs: string; partnerWithUsContent: string;
                 partnerWithUsCTA: string; exploreHeading: string;  footerText: string;  Interest: string; InterestCTA: string; partnerSubtext: string; footerCTA: string;
                 footerCopyrights: string;  partnerImg1: string;  partnerImg4: string; partnerImg2:string; partnerUsImg:string; partnerFlipkart:string; 
                 footerInsta:string; footerLinkedin:string; footerYoutube:string; footerX:string; footerFacebook: string; Launches:string;
                 LaunchesContent:string; partnerImg1Pre: string; top_banner_image: string; top_banner_image_mob: string;

  } = {
    sec1heading: '', sec1learnmore: '',sec1CTA:'', sec2Heading:'',  sec2Img:'', sec2Img2:'', sec2CTA:'', sec3Redefine:'', sec3Reimagine:'', partnerHeading:'',
    partnerOTO: '', partnerWithUs: '', partnerWithUsContent:'', partnerWithUsCTA:'', exploreHeading:'', footerText:'',  Interest:'',InterestCTA: '',
    partnerSubtext:'', footerCTA:'', footerCopyrights:'',  partnerImg1:'', partnerImg4:'', partnerImg2:'',partnerUsImg:'', partnerFlipkart:'',
    footerInsta:'', footerLinkedin:'', footerYoutube:'', footerX:'', footerFacebook:'',Launches:'',LaunchesContent:'', partnerImg1Pre:'',top_banner_image:'', top_banner_image_mob:'',

    }


  contactUs: { callUs: string; email: string; whatsApp: string } = {
    callUs: '',
    email: '',
    whatsApp: '',
  }
  productSpec: { productData: any, MVAppProductSpec: any } = {
    productData: [],
    MVAppProductSpec: [],
  }



  // homeDetails:HomeDetails = new HomeDetails()
  faqCategories: Array<FaqMasterCategory> = []
  faqDetail: FAQDetails = new FAQDetails()
  trendingFAQ: Array<QuestionAnswerDetail> = []
  faqAppliedSearch: FAQDetails = new FAQDetails()
  innovationHub: InnovationHubDetails = new InnovationHubDetails()
  whoWeAre: WhoWeAre = new WhoWeAre()
  LegalPolicy: LegalPolicy = new LegalPolicy()
  PrivacyPolicy: PrivacyPolicy = new PrivacyPolicy()
  joinWaitListkey : boolean = true
  appConfig: {joinWaitListkey:boolean } = {
    joinWaitListkey: true

  }
  blogDetails: Blogs =  new Blogs()
  blogsCategories: Array<BlogsCategory> = []
  joinWaitlistTC: JoinWaitlistTC = new JoinWaitlistTC()
  OnlinePreBookTC: OnlinePreBookTC = new OnlinePreBookTC()
  HeroSection1:HeroSection1=new HeroSection1()
  MotorSection1:MotorSection1=new MotorSection1()
  PowerSection1:PowerSection1=new PowerSection1()
  ThermalSection1:ThermalSection1=new ThermalSection1()
  HotspotSection1: HotspotSection1=new  HotspotSection1()
  ColorSection1:ColorSection1=new ColorSection1()
  VuiSection1:VuiSection1=new VuiSection1()
  AppSection1:AppSection1=new AppSection1()
  FeatureSection1:FeatureSection1=new FeatureSection1()
  MatterCareSection1:MatterCareSection1=new MatterCareSection1()
  PriceSection1:PriceSection1=new PriceSection1()  
  newsCategories: Array<NewsCategory> = []
  newsType: string = ''
  newsCategory: string = ''
  awards: Array<Awards> = []
  pressRelease: Array<PressReleases> = []
  leadership: Array<Leadership> = []
  logosAndTrademark: Array<LogosAndTrademark> = []
  aera5000: Array<Aera5000> = []
  aera5000Plus: Array<Aera5000Plus> = []
  productPortfolio: Array<ProductPortfolio> = []
  EMIDetails: { Bike5000: any, Bike5000Plus: any, priceBrackups: any } = {
    Bike5000: '',
    Bike5000Plus: '',
    priceBrackups: ''
  }
  downloadNews: boolean = false
}

class PromoCode {
  couponCode: string = ''
  createTime: string = ''
  updateTime: string = ''
  couponType: string = ''
  expired: boolean = false
  couponAmount: string = ''
  validityType: string = ''
  validityValue: string = ''
}

enum BIKE_MODEL_NAME {
  AERA_5000 = 'Aera 5000',
  AERA_5000_PLUE = 'Aera 5000+',
}
enum ORDER_STATUS {
  CANCELED = 'CANCELLED',
  IN_PROGRESS = 'IN-PROGRESS',
  MODIFIED = 'MODIFIED',
  SUCCESS = 'SUCCESS',
}

class RegisterYourInterest extends UserDetails {
  phone: string = ''
  otpVerifed: boolean = false
  verifiedPhoneNumber: string = ''
}

class ChannelPartnerFromData {
  city: string = ''
  state: string = ''
  phone: string = ''
  email: string = ''
  firstName: string = ''
  lastName: string = ''
  firmName: string = ''
}

class ChannelPartnerQuestion {
  charLimit: number = 0
  id: number = -1
  publishedAt: string = ''
  question: string = ''
  answer?: string = ''
}
class PartnerStage {
  id: number = 0
  publishedAt: string = ''
  partnerStage: number = -1
  categoryTitle: string = ''
  channel_partner_questions: Array<ChannelPartnerQuestion> = []
}

class partnerWirhUs {
  title: string = ''
  subTitle: string = ''
  pageDescription: string = ''
  publishedAt: string = ''
}

class PartnerAndChannel {
  phone: string = ''
  type: string = ''
  activeStep: number = 0
  isChannelPartnerInitiated: boolean = false
  partnerWithUs: partnerWirhUs = new partnerWirhUs()
  countries: any = []
  ChannelPartnerFromData: string = encryptDataWithKey(new ChannelPartnerFromData(),'')
  channelPartnerQuestions: Array<PartnerStage> = []
}

export class DealerDetails {
  distance : string = ''
  contactNo : string = ''
  dealerName: string = ''
  dealerAddress : string = ''
  lat : number = 0;
  lng : number = 0
  id : number = 0

}
class DealerLocator  {
  searchResult: any =[]
  allDealersList: Array<DealerDetails> = []
}

class Scheduling {

}

class LocatorAndScheduling {
  dealerLocator:DealerLocator = new DealerLocator()
  scheduling: Scheduling = new Scheduling()
}

export {
  PrivacyPolicy,
  LegalPolicy,
  Home,
  PromoCode,
  AERA_TYPE,
  AeraDetail,
  LOGIN_TYPE,
  AeraDetails,
  UserDetails,
  FAQDetails,
  FAQCategory,
  ORDER_STATUS,
  OrderDetails,
  LocationDetail,
  PREBOOKING_STEP,
  BIKE_MODEL_NAME,
  CreatePrebooking,
  FaqMasterCategory,
  OnboardingDetails,
  CreateOrderDetails,
  BILLDESK_PAYMENT_STATUS,
  RegisterYourInterest,
  PartnerAndChannel,
  PartnerStage,
  ChannelPartnerFromData,
  InnovationHubDetails,
  WhoWeAreRewards,
  WhoWeAreEvents,
  OurStoryEvent,
  WhoWeAreLeaders,
  Blogs,
  BlogsCategory,
  LocatorAndScheduling,
  CurrentUserDetails,
  News,
  NewsCategory,
  Awards,
  PressReleases,
  Leadership,
  LogosAndTrademark,
  Aera5000,
  Aera5000Plus,
  ProductPortfolio
}
