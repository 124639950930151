import Api from '../../api/index'
import { ActionTypes } from '../../constants'
import { endpoints } from '../../api/endPoints'
import { decryptData, setPartnerToken, setUserToken } from '../../utils/common'
import { LOGIN_TYPE } from '../../constants/model'
import { API_SUCCESS_CODE } from '../../api/constant'
import axios from 'axios'
import { ReducerModel } from '../../reducers/reducerModel'

const requestForOTP = (payload: any, success: Function, fail: Function) => {
  return (dispatch: Function, getState: Function) => {
    dispatch(loaderHandler(true))
    Api.postApiCall(
      endpoints.auth.postOtp,
      {
        phone: payload.phone,
        type: '',
      },
      (response: any) => {
        const { data, responseCode, error, message } = response
        if (data && responseCode == API_SUCCESS_CODE.success) {
          dispatch(loaderHandler(false))

          if (response?.data?.lockTime === 300000) {
            dispatch({
              type: ActionTypes.CHECK_FIVE_MINUTES,
              payload: { checkFiveMinutes: true },
            })
          } else {
            dispatch({
              type: ActionTypes.CHECK_FIVE_MINUTES,
              payload: { checkFiveMinutes: false },
            })
          }

          dispatch({
            type: ActionTypes.OTP_REQUEST_DETAILS,
            payload: data,
          })
          success(data)
        } else success(data)
      },
      (error: any) => {
        fail(error)
        dispatch(loaderHandler(false))
      }
    )
  }
}

const checkOTP = (
  payload: any,
  success: Function,
  fail: Function,
  type?: 'LOGIN' | 'RYI' | 'PREBOOK' | 'CHANNEL_PARTNER'
) => {
  return (dispatch: Function, getState: Function) => {
    const { otpAttemps } = getState().onboarding

    dispatch(loaderHandler(true))
    Api.postApiCall(
      endpoints.auth.otpValidate,
      payload,
      (response: any) => {
        const { data, responseCode, message, error, lockTime , resendTime } = response
        if (!error && responseCode == API_SUCCESS_CODE.success) {
          const { userDetails : {currentUserDetails} }: ReducerModel = getState()
          const userDetails = decryptData(currentUserDetails)

          if (type == 'LOGIN' || type == 'PREBOOK') {
            if (data && data.userProfile) {
              setUserToken(data)
              dispatch({
                type: ActionTypes.UPDATE_USER_DETAILS,
                payload: data,
              })
            }
            dispatch({
              type: ActionTypes.NUMBER_OTP_VERIFIED,
              payload: { numberOtpVerified: true },
            })
            dispatch(loaderHandler(false))
            return success({
              message,
              loginStatus:
                data && data.userProfile
                  ? LOGIN_TYPE.OLD_USER
                  : LOGIN_TYPE.NEW_USER,
            })
          }
          if (type == 'RYI') {
            dispatch({
              type: ActionTypes.UPDATE_RYI_USER_DETAILS,
              payload: {...data, userToken : ''},
            })
            if (userDetails.userToken) {
              setUserToken({...userDetails,userToken :data.userToken})
              dispatch({
                type: ActionTypes.UPDATE_USER_DETAILS,
                payload: {...userDetails, userToken :data.userToken },
              })
            }
           
          }

          if (type == 'CHANNEL_PARTNER') {
            dispatch(loaderHandler(false))
            setPartnerToken(data.userToken)
            return success(data)
          }
          return success(data)
        } else if (error) {
          console.log({ error })
          if (message === 'Incorrect OTP. Please try again.') {
            dispatch({
              type: ActionTypes.OTP_NUMBER_ATTEMPS,
              payload: { otpAttemps: otpAttemps - 1 },
            })
          }

          fail({ message, lockTime , resendTime })
          dispatch(loaderHandler(false))

          return
        }
        success()
      },
      (error: any) => {
        fail(error)
        dispatch(loaderHandler(false))
      }
    )
  }
}

const tabForwardMethod = (type: 'next' | 'back' | 'reset') => {
  return (dispatch: Function, getState: Function) => {
    const { tab, numberOtpVerified } = getState().onboarding
    if (type === 'next') {
      dispatch({
        type: ActionTypes.TAB_CHANGE,
        payload: { tab: tab + 1 },
      })
    } else if (type === 'back') {
      dispatch({
        type: ActionTypes.TAB_BACK,
        payload: { tab: tab - 1 },
      })
    } else {
      dispatch({
        type: ActionTypes.TAB_BACK,
        payload: { tab: 1 },
      })
    }
  }
}

const signupDetails = (payload: any) => {
  return (dispatch: Function, getState: Function) => {
    console.log({ getState })
    dispatch({
      type: ActionTypes.SIGNUP_DETAILS,
      payload: { signUpFromData: payload },
    })
  }
}

const registerUser = (payload: any, success: Function, fail: Function) => {
  console.log('API calling')
  return (dispatch: Function, getState: Function) => {
    dispatch(loaderHandler(true))

    Api.postApiCall(
      endpoints.auth.registerUser,
      payload,
      (response: any) => {
        const { responseCode, data, error, message } = response
        if (data && !error && responseCode == API_SUCCESS_CODE.success) {
          setUserToken(data)

          dispatch({
            type: ActionTypes.UPDATE_USER_DETAILS,
            payload: data,
          })
          success({
            ...data,
            message,
            loginStatus: LOGIN_TYPE.OLD_USER,
          })
          dispatch(loaderHandler(false))

          return
        }
        if (error && !data) {
          fail({ message })
          dispatch(loaderHandler(false))

          return
        }
        success()
      },
      (error: any) => {
        fail(error)
        dispatch(loaderHandler(false))
      }
    )
  }
}

const loaderHandler = (status: boolean) => {
  return (dispatch: Function, getState: Function) => {
    const { loaderStatus } = getState().onboarding

    dispatch({
      type: ActionTypes.LOADER_STATUS,
      payload: { loaderStatus: status },
    })
  }
}

export default {
  checkOTP,
  registerUser,
  requestForOTP,
  signupDetails,
  tabForwardMethod,
  loaderHandler,
}
