import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Images from '../../../utils/images'
import LinearProgress from '@mui/material/LinearProgress'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useDispatch, useSelector } from 'react-redux'
import UserActions from '../../../actions/userActions'
import { useHistory, useLocation } from 'react-router-dom'
import PrebookingActions from '../../../actions/prebookingActions'
import { ReducerModel } from '../../../reducers/reducerModel'
import moment from 'moment'
import { decryptData, generateOrdinal, getSelectedBikeByColor, tagMethodGTM } from '../../../utils/common'
import userActions from '../../../actions/userActions'
import Icons from '../../../utils/icons'
import AppLoader from '../../../components/AppLoader'
import { RootState } from '../../../reducers'
import Toaster from '../../../components/Toaster'
import { AERA_TYPE,ORDER_STATUS, OrderDetails } from '../../../constants/model'
import prebookingActions from '../../../actions/prebookingActions'
import homeActions from '../../../actions/homeActions'
import MatterShop from '../component/matterShop'


// interface Props {
//   forwardProfile: any
// }

const MyAccount = ({ setShowMessage }: any) => {
  const history = useHistory()
  const [messageToast, setMessageToast] = React.useState("")
  const location: any = useLocation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [isLoading, setLoading] = React.useState(false)
  // const userData = useSelector((store: RootState) => store.userDetails)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const dispatch: any = useDispatch()

  useEffect(() => {


    setShowMessage(false)
    window.scrollTo(0, 0)
    setLoading(true)
    dispatch(homeActions.getCancelReasons())
    dispatch(UserActions.getUsersDetails())
    dispatch(userActions.checkProfilePercentge())
    dispatch(PrebookingActions.getAvailableAeras())
    dispatch(
      UserActions.getOrderSubcription(
        () => {
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        },
        () => {
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        }
      )
    )
    return () => {
      // setTimeout(() => {
      //   history.replace({ state: {} })
      // }, 100)
    }
  }, [])


  const { orderList,currentUserDetails } = useSelector(
    (store: ReducerModel) => store.userDetails
  )

  const userOrderList = React.useMemo(() =>{    
    if(decryptData(orderList).orderList !== undefined) {      
      return decryptData(orderList)
    } else {      
      return {orderList: []}
    }    
  },[orderList])

  const { imageBase64, firstName, lastName, profilePercentage } = React.useMemo(() => {
    return decryptData(currentUserDetails)
  }, [currentUserDetails])


  const onClickPrebook = (type?: string) => {
    dispatch(prebookingActions.prebookValidate((data: any) => {
      if (data.preBookEnabled) {
        if (type === "fiveHunderd") {
          history.push('/booknow', {
            productId: AERA_TYPE.FIVE_THOUSHAND,
          })
        } else if (type === "fiveHunderdPlus") {
          history.push('/booknow', {
            productId: AERA_TYPE.FIVE_THOUSHAND_PLUS
          })
        }
      } else {
        setMessageToast(`BOOKING STARTS 17th May`)
      }
    }, () => { }))
    setMessageToast("")
  }



  return (
    <div className="my-account-page">
      {/* {location?.state?.message ? (
        <Toaster successMessage={location.state.message} />
      ) : null} */}
      {messageToast && (
        <Toaster successMessage={messageToast} failMessage="" />
      )}
      <Grid container spacing={{ xs: 2, sm: 3, lg: 4 }}>
        <Grid item xs={12} lg={6}>
          <div
            className="breif-info-card card flex js-start pointer"
            onClick={() => history.push('my-account/profile')}
            title="Click to see your profile"
          >
            <div className="profile-img-cover">
              <img src={imageBase64 || Images.DummyUser} alt="" />
            </div>
            <div className="info">
              <h5
                className="monument"
                title={firstName + ' ' + lastName}
              >
                {firstName} {lastName}
              </h5>
              {/* <p className="ex-small mt-5 mt-xs-0">Rider </p> */}
              <div className="profile-completion">
                <div className="right">
                  <p className="ex-small subTextLight mb-8 mb-sm-4">
                    You are {profilePercentage}% there
                  </p>
                </div>
                <LinearProgress
                  variant="determinate"
                  value={profilePercentage}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className="coming-soon-card card h-100"></div>
        </Grid>
        <Grid item xs={12}>
          <div className="orders-card card">
            <h5 className="monument mb-36 mb-sm-16">Orders & Subscriptions</h5>
            {userOrderList.orderList.length ? (
              <Grid container spacing={{ xs: 2, sm: 3, lg: 4 }}>
                {userOrderList.orderList.map((item: OrderDetails) => {
                  const date = moment(item.timeStamp).get('D')
                  let selectedBikeImage =
                    getSelectedBikeByColor(item)?.bikeImage || Images.AeraBike
                  return (
                    <Grid key={item.orderId} item sm={12} md={6}>
                      <div className="single-order">
                        { item.orderStatus != ORDER_STATUS.SUCCESS ? <p className={`small bold order-status uppercase mb-8 ${item.orderStatus === ORDER_STATUS.CANCELED? 'failure' : 'success'}`}>
                          {item.orderStatus }
                        </p> :  <p className={`small bold order-status uppercase mb-8 success`}>
                          {!item.modifiable ? 'modified' : 'Booked' }
                        </p>
                        }
                        <div className="bike">
                          <img src={selectedBikeImage || Images.BlackBike} />
                        </div>
                        <div className="flex al-center js-between white">
                          <div>
                            <h5 className="monument mb-10 mb-sm-4 uppercase">
                              {item.bikeModelName}
                            </h5>
                            {item.timeStamp && (
                              <p className="small subTextLight">
                                {generateOrdinal(date) +
                                  '' +
                                  moment(item.timeStamp).format(` MMM YYYY`)}
                              </p>
                            )}
                          </div>
                          <button
                            className="btn btn-black-active small"
                            onClick={() =>{
                              tagMethodGTM("Orders & Subscriptions" , `Matter Orders & Subscriptions` , "Click" , `${item.bikeModelName} View Order Details`);
                              history.push(
                                `my-account/order-detail/${item.orderId}`
                              )}
                            }
                          >
                            View Order Details
                          </button>
                        </div>
                      </div>
                    </Grid>
                  )
                })}
              </Grid>
            ) : (
              <div className="no-orders-yet">
                <p className="subText monument center">
                  No orders yet! Hang back to see, compare, configure or maybe
                  do the prebooking! Shall we?
                </p>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="create-aera-card card">
            <div className="create-aera-text">
              <p className="ex-small monument primary mb-15 mb-xs-10">
                CREATE YOUR AERA
              </p>
              <h4 className="white monument">
                Start Configuring your Aera as per your taste and choice
              </h4>
            </div>
            <div className="choose-aera mt-30 mt-xs-20">
              <div className="btn-clipped-cover w-100">
                <button
                  onClick={() => {
                    tagMethodGTM("Login", `Matter - Create your Aera`, "Click", "AERA 5000");
                    onClickPrebook("fiveHunderd")
                  }}
                  className="btn-clipped black-clipped w-100 mb-16 monument"
                >
                  <img src={Images.Aera5000} alt='Matter Aera 5000 Image' className="aera-model-img" />
                  AERA 5000
                  <img
                    src={Icons.NextChevronIcon}
                    className="right-chevron-icon"
                    alt='Next Caret Icon'
                  />
                </button>
              </div>
              <div className="btn-clipped-cover w-100">
                <button
                  onClick={() => {
                    tagMethodGTM("Login", `Matter - Create your Aera`, "Click", "AERA 5000+");
                    onClickPrebook("fiveHunderdPlus")
                  }}
                  className="btn-clipped black-clipped w-100 monument"
                >
                  <img src={Images.Aera5000Plus} alt='Matter Aera 5000 Plus Image' className="aera-model-img" />
                  AERA 5000+
                  <img
                    src={Icons.NextChevronIcon}
                    className="right-chevron-icon"
                    alt='Next Caret Icon'
                  />
                </button>
              </div>
            </div>
          </div>
        </Grid>
        {false && (
          <Grid item xs={12}>
            <div className="card my-favourite-card">
              <div className="flex al-center js-between mb-20 heading-area mb-xs-10">
                <h5 className="mb-20 monument mb-xs-10">My Favourites</h5>
                <ul className="custom-tab mb-20 mb-xs-10">
                  <li>
                    <div className="single-tab monument active">My Saved</div>
                  </li>
                  <li>
                    <div className="single-tab monument">My Reserved</div>
                  </li>
                </ul>
              </div>
              <Grid
                container
                columnSpacing={{ xs: 1, sm: 2, lg: 3 }}
                rowSpacing={{ xs: 3, sm: 5, lg: 6 }}
              >
                <Grid item xs={12} sm={6} lg={4}>
                  <div className="saved-aera-card">
                    <div className="bike-cover">
                      <img src={Images.BlackBike} />
                      <IconButton
                        color="inherit"
                        aria-label="Click to open menu"
                        className="menu-icon"
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                      >
                        <MoreHorizIcon className="white" />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        className="black-bg-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem onClick={handleClose}>EDIT</MenuItem>
                        <MenuItem onClick={handleClose}>DELETE</MenuItem>
                      </Menu>
                    </div>
                    <div className="saved-aera-info flex al-center js-between">
                      <div>
                        <h6 className="monument small mb-5">AERA 5000+</h6>
                        <p className="ex-small">12th Mar 2023</p>
                      </div>
                      <button className="btn btn-primary small">PREBOOK</button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <div className="saved-aera-card">
                    <div className="bike-cover">
                      <img src={Images.BlackBike} />
                    </div>
                    <div className="saved-aera-info flex al-center js-between">
                      <div>
                        <h6 className="monument small mb-5">AERA 5000+</h6>
                        <p className="ex-small">12th Mar 2023</p>
                      </div>
                      <button className="btn btn-primary small">PREBOOK</button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <div className="saved-aera-card">
                    <div className="bike-cover">
                      <img src={Images.BlackBike} />
                    </div>
                    <div className="saved-aera-info flex al-center js-between">
                      <div>
                        <h6 className="monument small mb-5">AERA 5000+</h6>
                        <p className="ex-small">12th Mar 2023</p>
                      </div>
                      <button className="btn btn-primary small">PREBOOK</button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <div className="add-aera-to-save-cover">
                    <AddIcon fontSize="large" />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <div className="add-aera-to-save-cover">
                    <AddIcon fontSize="large" />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}
        {/* <Grid item xs={12}>
          <MatterShop />
        </Grid> */}
      </Grid>
      {isLoading && <AppLoader />}
    </div>
  )
}

export default MyAccount
